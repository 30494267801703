import { DataExchangeService } from './../services/data-exchange.service';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { ApiService } from '../services/api.service';
import { ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication/authentication.service';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';
import { NotInKscopeComponent } from '../not-in-kscope/not-in-kscope.component';
import { OptedInComponent } from '../opted-in/opted-in.component';
import { IFindCustomerMktProgram } from '../interfaces/find-customer-mkt-program';
import { ICustomer } from '../interfaces/customer';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    HeaderComponent,
    CommonModule,
    NotInKscopeComponent,
    OptedInComponent,
    LoadingSpinnerComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HomeComponent {
  public optInStatus: string = '';
  public optInData: IFindCustomerMktProgram = {} as IFindCustomerMktProgram;
  public cantFindSoldTo: boolean = false;
  public somethingWrong: boolean = false;
  public soldToSalesOrg: string = '';
  public customer: ICustomer = {} as ICustomer;
  public userCredential: any = {};
  public loadingSpinner: string = '';

  constructor(
    public apiService: ApiService,
    public authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private dataExchangeService: DataExchangeService
  ) {}

  ngOnInit(): void {
    this.dataExchangeService.currentOptInStatus.subscribe((status) => {
      this.optInStatus = status;
    });
    this.dataExchangeService.currentLoadingSpinnerStatus.subscribe((status) => {
      this.loadingSpinner = status;
    });
  }

  public findSoldToForm = this.formBuilder.group({
    findSoldTo: ['', Validators.required],
  });

  // find sold to function - if we get optInStatus - call getUserCredentials
  public async findSoldTo() {
    // reset everything
    this.optInStatus = '';
    this.optInData = {} as IFindCustomerMktProgram;
    this.cantFindSoldTo = false;
    this.somethingWrong = false;
    this.soldToSalesOrg = '';
    this.customer = {} as ICustomer;
    this.userCredential = {};

    var soldToNumber = this.findSoldToForm.get('findSoldTo')!.value ?? '';
  
    if (soldToNumber != '') {
      try {
        // get user credentials for salesOrg
        this.userCredential = (await firstValueFrom(this.apiService.getUserCredentials(soldToNumber)))?.userCredential;
        const salesOrg = this.userCredential?.salesOrg;
        const customerName = this.userCredential?.name;
  
        if (salesOrg) {
          // soldToNumber needs leading 000 for findCustomerMktProgram call
          soldToNumber = ('0000000000' + soldToNumber).slice(-10);
  
          // get customer mkt program - for optInStatus and date to display
          this.optInData = (await firstValueFrom(this.apiService.findCustomerMktProgram(soldToNumber, salesOrg))) ?? {} as IFindCustomerMktProgram;

          // register user if there isn't a mktProgramName
          if(!this.optInData.mktProgramName) {
            const params: IFindCustomerMktProgram = {
              soldTo: soldToNumber,
              salesOrg,
              mktProgramName: 'ARP',
              eligibility: 'Y',
              optInStatus: '',
              autoShip: '',
            } as any as IFindCustomerMktProgram;

            const reg = await firstValueFrom(this.apiService.registerUser(params));
            if (reg?.serviceStatus?.resultCode === 0) {
              this.optInStatus = 'N';
              this.optInData = params;
            } else {
              // check if record was inserted during registration - from 1st call
              this.optInData = (await firstValueFrom(this.apiService.findCustomerMktProgram(soldToNumber, salesOrg))) ?? {} as IFindCustomerMktProgram;
              if(!this.optInData.mktProgramName) { 
                console.log('Something went wrong with registration: record inserted but service returned error');
                this.somethingWrong = true;
              } else {
                this.optInStatus = 'N';
                this.optInData = params;
              }
            }
          } else {
            const optInStatus = (this.optInData?.optInStatus ?? '').toUpperCase();
            
            // if no status - set to 'N' and update service
            this.optInStatus = optInStatus === 'Y' ? 'Y' : 'N';
          }

          // get soldTo data for address and name to display
          const soldToData = await firstValueFrom(this.apiService.getSoldToData(soldToNumber, salesOrg));
          this.customer = soldToData?.customers?.find((x: ICustomer) => x.accountNumber === soldToNumber) || ({} as ICustomer);
          if(!this.customer) {
            this.customer = { customerName } as ICustomer; 
          }
        } else {
          this.cantFindSoldTo = true;
        }
      } catch (error) {
        console.error(error);
        this.cantFindSoldTo = true;
      }
    }
  }
}
